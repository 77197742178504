import React from 'react';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../../core/Utils/SitecoreFields';

const CofFooter = ({ fields }) => {
  return (
    <div className="generic-footer" style={{ border: '1px solid black' }}>
      <div className="container">
        <div className="generic-footer-content">
          <div className="generic-footer-left">
            <div className="generic-footer-title">
              <Text field={fields.Title} />
            </div>

            <div className="generic-footer-email">
              <Link field={fields.email} />
            </div>

            <div className="generic-footer-links">
              <Link field={fields.terms} />
              <Link field={fields.privacy} />
            </div>
          </div>

          <div className="generic-footer-right">
            <RichText
              tag="div"
              className="generic-footer-text"
              field={fields.text}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CofFooter ;
